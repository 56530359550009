import {Component, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {HiveApiService} from '../hive-api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {RoutePaths} from '@core/constants/route.path';

@DomainResource({
  name: 'CREATE_HIVE',
  access: [Roles.ADMIN, Roles.USER]
})
@Component({
  selector: 'app-create-hive',
  template: `
    <app-hive-container [sidebar]="false">
      <section class="create-hive-container">
        <app-create-hive-input [hive]="hive" (hiveContent)="create($event)">
        </app-create-hive-input>
        <!--<div class="create-hive-actions button-container">
          <button class="button active btn-rounded" [class.disabled]="!!hive" [disabled]="!!hive"
                  (click)="create()">
            Create
          </button>
        </div>-->
        <!--<app-hive-invite class="add-margin-top-15" [hiveId]="hive && hive._id"></app-hive-invite>
        <div class="create-hive-actions button-container">
          <button (click)="back()" class="button btn-rounded btn-no-shadow">Back</button>
        </div>-->

        <popup-layout *ngIf="showHiveLogin">
          <section class="padding-box">
            <div class="add-margin-top-15">
              <label>Please login or sign up to sharebee to enroll to the hive</label>
            </div>
            <div class="button-container ms-fix-container align_center margin--bottom">
              <bee-btn type="secondary" (click)="loginUser()">Login</bee-btn>
              <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
            </div>
            <p class="close-button" (click)="showHiveLogin = false">close</p>
          </section>
        </popup-layout>
      </section>
    </app-hive-container>
  `,
  styleUrls: ['./create-hive.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateHiveComponent {

  name;
  hiveName = '';
  imagePath;
  hive: any;
  isUserLoggedIn = false;
  showHiveLogin = false;

  constructor(public translate: TranslateService,
              private api: HiveApiService,
              private router: Router,
              private toast: ToastrService) {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
  }

  /*nameChanged(name) {
    this.hiveName = name;
  }*/

  create(hiveObj) {
    console.log(hiveObj, this.isUserLoggedIn);
    if (this.isUserLoggedIn) {
      if (hiveObj.name && hiveObj.description) {
        this.api.createHive({
          name: hiveObj.name,
          hiveImagePath: hiveObj.hiveImagePath,
          description: hiveObj.description
        })
          .subscribe(({data}) => {
            this.toast.success('Hive created');
            this.hive = data;
            this.router.navigate([`/users/my-hive/${this.hive._id}/members`]);
            // this.router.navigate(['/users/my-hive']);
          }, (error) => {
            this.toast.error('Unable to created hive');
          });
      } else {
        this.toast.error('Hive name & description are required');
      }
    } else {
      this.showHiveLogin = true;
    }
  }

  back() {
    this.router.navigate(['/users/my-hive']);
  }

  loginUser() {
    this.router.navigate([RoutePaths.LOGIN_USER]);
  }

  signUpUser() {
    this.router.navigate([RoutePaths.HOME_GUEST_REGISTER]);
  }
}
