import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-hive-list',
  template: `
      <div class="hive-list-container">
        <app-hive-header
          [title]="title"
          [primaryActionLabel]="primaryActionLabel"
          (primaryAction)="primaryAction.emit()"
          [secondaryActionLabel]="secondaryActionLabel"
          (secondaryAction)="secondaryAction.emit()">
        </app-hive-header>
        <div *ngIf="items.length; else empty" class="hive-list">
          <ng-container *ngFor="let item of items">
            <div class="hive-list-item">
              <img class="hive-list-item-img" src="{{item.hiveImagePath}}"
                   onError="this.src='assets/img/no_image_available.jpeg'">
              <div class="hive-list-title-container">
                <div class="hive-list-item-title">{{ item.title }}</div>
                <div class="hive-list-item-subtitle">{{ item.subtitle }} {{item?.email}}</div>
              </div>
              <div class="fill-gape"></div>
              <div class="hive-list-item-actions">
                <bee-btn [block]="true" size="xsmall" *ngIf="isOwner" (click)="itemSecondaryAction.emit(item)" >{{ itemSecondaryActionLabel }}</bee-btn>
                <bee-btn [block]="true" size="xsmall" *ngIf="itemPrimaryActionLabel" (click)="itemPrimaryAction.emit(item)">{{ itemPrimaryActionLabel }}</bee-btn>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-template #empty>
          <div class="empty-message"> {{ emptyMessage }}</div>
        </ng-template>
      </div>
  `,
  styleUrls: [ './hive-list.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class HiveListComponent {

  @Input() isOwner = false;
  @Input() emptyMessage = '';
  @Input() title = '';
  @Input() items: {
    title: string,
    subtitle: string,
    image: string,
    hiveImagePath: string
  }[];

  @Input() primaryActionLabel: string;
  @Input() secondaryActionLabel: string;
  @Output() primaryAction = new EventEmitter();
  @Output() secondaryAction = new EventEmitter();

  @Input() itemPrimaryActionLabel: string;
  @Input() itemSecondaryActionLabel: string;
  @Output() itemPrimaryAction = new EventEmitter();
  @Output() itemSecondaryAction = new EventEmitter();

  constructor(public translate: TranslateService) {}
}
