import {Component} from '@angular/core';
import {ApiService} from '@modules/usermodule/src/lib/user-auth/shared/api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {RoutePaths} from '@core/constants/route.path';

@Component({
    selector: 'app-auth-user-forgot-password',
    template: `
        <app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="'sign_up'">
            <section class="app-auth-user-login">
                <div class="login-container">
                    <div class="input-container">
                        <h1 class="add-margin-btm-15">lost password</h1>
                        <div class="login-capsule add-margin-top-15">
                            <label class="input--label add-margin-btm-15">Enter your registered email address to reset
                                your
                                password</label>
                            <label class="input--label add-margin-top-15">Email</label>
                            <input type="text" class="input--field" [(ngModel)]="email">
                            <div class="btn-container add-margin-top-20">
                              <bee-btn type="secondary" (click)="loginPage()">back</bee-btn>
                                <bee-btn type="primary" (click)="submitForm()">reset</bee-btn>
                            </div>
                        </div>
                    </div>
                    <div class="img--container">
                        <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
                    </div>
                </div>
            </section>
        </app-header-footer-layout>`,
    styleUrls: ['./../login/login.component.scss']
})
export class AuthForgotPasswordComponent {
    email: any;

    constructor(private apiService: ApiService,
                private toastrService: ToastrService,
                private router: Router,
                private spinner: NgxSpinnerService) {
    }

    submitForm() {
        this.spinner.show();
        const params: { email: string } = {email: this.email};
        this.apiService.sendResetPasswordLink(params)
            .subscribe(
                (result: any) => this._sendLinkSuccess(result),
                (error) => this._sendLinkError(error)
            );
    }

    private _sendLinkSuccess(result: any) {
        this.spinner.hide();
        this.toastrService.success('We have sent you a reset link, please check your inbox', '');
        this.router.navigate(['users/auth/password-recovery-notification']);
    }

    private _sendLinkError(error: any) {
        this.spinner.hide();
        if (error.error === 'USER_NOT_FOUND') {
            this.toastrService.error('Error', 'Sorry your email address can\'t be found in our system, please contact help@sharebee.co.uk for assistance');
        } else {
            this.toastrService.error('Error', 'Something went wrong');
        }
    }

  loginPage() {
    this.router.navigate([RoutePaths.LOGIN_USER]);
  }
}
