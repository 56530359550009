import {Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomainResource} from '@modules/usermodule/src/lib/user-auth/configs/config.helper';
import {Roles} from '@modules/usermodule/src/lib/user-auth/shared/user.services';
import {EndpointsService} from '../../shared/constants/endpoints.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-create-hive-input',
  template: `
    <section>
      <div class="create-hive-input">
        <div>
          <label class="padding-bottom-5">hive image</label>
          <div class="item-list__image" *ngIf="!isReadOnly">
            <img class="hive_image_view" [src]="imagePath" onError="this.src='assets/img/no_image_available.jpeg'">
          </div>

          <div class="item-list__image" *ngIf="isReadOnly">
            <file-uploader
              class="hive-item-file-uploader"
              [upload_url]="uploadImage"
              [file_formats]="['jpg', 'jpeg','JPEG','JPG','png', 'PNG']"
              [file_type]="['image']"
              [allow_retry]="true"
              [max_file_size]="1e+9"
              [disabled]=""
              [img_path]="imagePath"
              [crop_disable]="false"
              [(isFileUploaded)]="isFileUploaded"
              (fileUploadTrigger)="imageUploading($event)"
              (onUploadDone)="onFileUploadDone($event,0)"
              (onValidationError)="safeCall($event)">
              <div class="uploader-title">
                <div class="image--placeholder"></div>
                <!--<img src="../../../assets/svg/plus.svg">-->
                <!--Drag and drop or select-->
              </div>
            </file-uploader>
          </div>
        </div>
        <div class="hive-name-container">
          <label>Name your hive</label>
          <input [(ngModel)]="name" [readonly]="!isReadOnly" class="input-field" type="text">
          <div class="add-margin-top-15">
            <label>description</label>
            <textarea rows="3" [readonly]="!isReadOnly" class="hive_description add_text-area__padding"
                      [(ngModel)]="description"></textarea>
          </div>
        </div>
      </div>
      <div class="hive_button add-margin-top-20" *ngIf="isReadOnly">
        <!--<button class="bee-btn bee-btn__primary" (click)="updateHive()">
          {{buttonTitle}}
        </button>-->
        <bee-btn type="primary" (click)="updateHive()">{{buttonTitle}}</bee-btn>
      </div>
    </section>
  `,
  styleUrls: ['./create-hive-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateHiveInputComponent implements OnChanges {

  hiveList = [];
  hiveMembers = [];
  @Input() buttonTitle = 'Create';
  @Input() isReadOnly = true;
  @Input() hive: { name: string, hiveImagePath: string, description };
  @Output() hiveContent = new EventEmitter();
  imagePath;
  name;
  description;
  /*@Input() name;
  @Input() hiveDescription;
  @Output() nameChange = new EventEmitter();
  @Output() uploadedHivePath = new EventEmitter();*/
  hiveImagePath = '';
  isFileUploaded?: boolean;
  uploadImage = '';
  uploadedImagePath = '';
  isUserLoggedIn: boolean;

  // constructor() {
  //   this.hive.name ? name = this.hive.name;
  // }

  get hiveListTitle() {
    return `my hives (${this.hiveList.length})`;
  }

  get memberListTitle() {
    return `my memberships (${this.hiveMembers.length})`;
  }

  constructor(public translate: TranslateService,
              private toastrService: ToastrService,
              private spinner: NgxSpinnerService,
              public endpoints: EndpointsService) {
    if (localStorage.getItem('User_Data')) {
      this.isUserLoggedIn = true;
    }
    this.uploadImage = this.endpoints.IMAGE_UPLOAD;
    console.log(this.hive);
  }

  ngOnChanges() {
    if (this.hive !== undefined) {
      this.name = this.hive.name;
      this.imagePath = this.hive.hiveImagePath;
      this.description = this.hive.description;
    } else {
      this.hive = {name: '', hiveImagePath: '', description: ''};
    }
  }

  func(e) {
    console.log(e);
  }

  imageUploading(obj: any) {
    this.spinner.show();
  }

  /**
   * Once file is uploaded save the path of image to itemObject array
   * @param1 model
   * @param2 pos
   */
  onFileUploadDone(model, pos) {
    this.spinner.hide();
    model.value = model.data.file.file.path;
    this.uploadedImagePath = model.data.file.file.path;
    this.imagePath = this.uploadedImagePath;
    // this.hive.hiveImagePath = this.uploadedImagePath;
    // this.uploadedHivePath.emit(this.uploadedImagePath);
  }

  uploadedHivePath() {
    this.hiveContent.emit(this.hive);
  }

  /*nameChanged(name) {
    // console.log(name);
    this.nameChange.emit(name);
  }*/

  safeCall(event: any): void {
    this.spinner.hide();
    this.toastrService.error('Error');
  }

  updateHive() {
    console.log(this.name);
    console.log(this.imagePath);
    console.log(this.description);
    if (this.name !== undefined || this.description !== undefined || this.imagePath !== undefined) {
      this.hive['name'] = this.name;
      this.hive['hiveImagePath'] = this.imagePath;
      this.hive['description'] = this.description;
      this.hiveContent.emit(this.hive);
    } else {
      this.isUserLoggedIn ? this.toastrService.error('Please update all fields') : this.hiveContent.emit(this.hive);
    }
  }
}
